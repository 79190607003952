import React from "react";
import { connect, useSelector } from "react-redux";
import { ForexQuery } from "../../prismic/staticQueries";

import Layout from "../../layout";
import { ContentWithTable } from "../../components/common";
import BANNER from "../../images/siteImages/forex/MARKETS_BANNER.jpg";

const Forex = () => {
  const language = useSelector((state) => state.language);
  const forexData = ForexQuery(language);

  return (
    <Layout>
      <ContentWithTable
        multitable={true}
        {...forexData}
        banner_image={BANNER}
        show_stats={false}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(Forex);
